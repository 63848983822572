:root {
  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*========== System color ==========*/
  --system-50: #f3faf7;
  --system-100: #def7ec;
  --system-200: #bcf0da;
  --system-300: #84e1bc;
  --system-400: #31c48d;
  --system-500: #0e9f6e;
  --system-600: #057a55;
  --system-700: #046c4e;
  --system-800: #03543f;
  --system-900: #014737;

  /*========== Colors ==========*/
  --white-color: #ffffff;
  --white-border: #ced4da;
  --gray-50: #f9fafb;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --gray-900: #111928;
  --green-color: #057a55;
  --ad-primary-400: #31c48d;
  --ad-primary-500: #0e9f6e;
  --ad-primary-700: #046c4e;
  --red-50: #fdf2f2;
  --red-500: #f05252;
  --red-600: #e02424;
  --red-700: #c81e1e;
  --blue-50: #ebf5ff;
  --blue-800: #1e429f;

  /*========== Borders radius ==========*/
  --rounded-lg: 8px;

  /*========== Paddings ==========*/
  --padding-8: 32px;
  --padding-10: 40px;
  --padding-12: 48px;
  --padding-24: 96px;

  /*========== Gaps ==========*/
  --gap-4: 16px;
  --gap-5: 20px;
  --gap-6: 24px;
  --gap-8: 32px;
}
